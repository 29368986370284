<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비유형" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비관리번호" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비명" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table1"
        isTitle
        title="설비점검이력"
        :columns="grid.columns"
        :data="grid.data"
        gridHeight="300px"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['checkStatusCd']">
            <q-chip outline square
              :color="setTagColor(props.row.checkStatusCd)"
              :clickable="true"
              :class="props.row.checkStatusCd ==='MCSC000020' ? 'blinking' : null"
              text-color="white"
              @click="btnClicked(props.row)">
              <q-avatar v-if="props.row.checkStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
              {{setTagName(props.row.checkStatusCd)}}
            </q-chip>
          </template>
        </template>
      </c-table>
      <c-table
        ref="table2"
        isTitle
        title="설비정비이력"
        :columns="grid2.columns"
        :data="grid2.data"
        gridHeight="300px"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['maintenanceStatusCd']">
            <q-chip outline square
              :color="setTagColor(props.row.maintenanceStatusCd)"
              :class="props.row.maintenanceStatusCd ==='MCSC000020' ? 'blinking' : null"
              :clickable="true"
              text-color="white"
              @click="btnClicked2(props.row)">
              <q-avatar v-if="props.row.maintenanceStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
              {{setTagName(props.row.maintenanceStatusCd)}}
            </q-chip>
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-inspect',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      searchParam: {
        plantCd: null,
        equipmentCheckKindCd: null,
        checkStatusCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        equipmentTypeCd: '',
        inspectionCycleCd: '',
        grade: '',
        equipmentCd: this.param.equipmentCd,
      },
      checkTypeCdItems: [], // 점검유형
      checkStepCdItems: [], // 점검진행상태
      checkNoPlanItems: [], // 점검진행상태
      colorItems: [],
      grid: {
        columns: [
          {
            name: "checkStatusCd",
            field: "checkStatusCd",
            label: "진행상태",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "checkScheduleDate",
            field: "checkScheduleDate",
            label: "점검예정일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "checkScheduleUserName",
            field: "checkScheduleUserName",
            style: 'width:100px',
            label: "점검예정자",
            align: "center",
            sortable: true,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            label: "점검부서",
            align: "center",
            sortable: true,
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            label: "점검완료일",
            align: "center",
            sortable: true,
          },
          {
            name: "checkResultName",
            field: "checkResultName",
            style: 'width:80px',
            label: "점검결과",
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCheckKindNm",
            field: "equipmentCheckKindNm",
            label: "점검유형",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            label: "점검주기",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            label: "계획/무계획",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: "maintenanceStatusCd",
            field: "maintenanceStatusCd",
            label: "진행상태",
            style: 'width:100px',
            align: "center",
            sortable: true,
            type: 'custom',
          },
          {
            name: "maintenanceDueDate",
            field: "maintenanceDueDate",
            label: "정비예정일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDueUserName",
            field: "maintenanceDueUserName",
            style: 'width:100px',
            label: "정비예정자",
            align: "center",
            sortable: true,
          },
          {
            name: "memDeptEntrantUserName",
            field: "memDeptEntrantUserName",
            style: 'width:110px',
            label: "정비부서 입회자",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceUserName",
            field: "maintenanceUserName",
            style: 'width:100px',
            label: "실제정비자",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDate",
            field: "maintenanceDate",
            label: "정비완료일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "inspResultAfterMemName",
            field: "inspResultAfterMemName",
            label: "정비결과",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCheckTypeName",
            field: "equipmentCheckTypeName",
            label: "정비유형",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "pmCycleName",
            field: "pmCycleName",
            label: "정비주기",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            label: "계획/무계획",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      listUrl2: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.checkNoPlanItems = [
        { code: 'Y', codeName: this.$language('무계획') },
        { code: 'N', codeName: this.$language('계획') }
      ] 
      this.colorItems = [
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$language('계획수립'), colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$language('점검중'), colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$language('점검완료'), colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$language('지연'), colorClass: 'red' },
      ]
      this.listUrl = selectConfig.sop.min.equipment.plan.list.url;
      this.listUrl2 = selectConfig.sop.min.maintenance.plan.list.url;
      this.getList();
      this.getList2();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getList2() {
      // 전체조회
      this.$http.url = this.listUrl2;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    btnClicked(row) {
      this.popupOptions.title = '설비점검결과 상세'; 
      this.popupOptions.param = {
        minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        title: '설비점검결과',
        selectData: [],
        noPlan: 'N',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/mim/inspection/equipmentResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '100%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnClicked2(row) {
      this.popupOptions.title = '설비정비결과 상세'; 
      this.popupOptions.param = {
        minEquipmentMaintenanceId: row.minEquipmentMaintenanceId ? row.minEquipmentMaintenanceId : '',
        selectData: [],
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/mim/maintenance/equipmentMaintenanceResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '100%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  }
};
</script>