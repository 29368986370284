var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "사업장", value: _vm.param.plantName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "설비유형",
                      value: _vm.param.equipmentTypeName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "설비관리번호",
                      value: _vm.param.equipmentNo,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "설비명", value: _vm.param.equipmentName },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-table", {
          ref: "table1",
          attrs: {
            isTitle: "",
            title: "설비점검이력",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: "300px",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["checkStatusCd"]
                    ? [
                        _c(
                          "q-chip",
                          {
                            class:
                              props.row.checkStatusCd === "MCSC000020"
                                ? "blinking"
                                : null,
                            attrs: {
                              outline: "",
                              square: "",
                              color: _vm.setTagColor(props.row.checkStatusCd),
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.btnClicked(props.row)
                              },
                            },
                          },
                          [
                            props.row.checkStatusCd === "MCSC000020"
                              ? _c("q-avatar", {
                                  attrs: {
                                    icon: "alarm",
                                    color: "red",
                                    "text-color": "white",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setTagName(props.row.checkStatusCd)
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _c("c-table", {
          ref: "table2",
          attrs: {
            isTitle: "",
            title: "설비정비이력",
            columns: _vm.grid2.columns,
            data: _vm.grid2.data,
            gridHeight: "300px",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["maintenanceStatusCd"]
                    ? [
                        _c(
                          "q-chip",
                          {
                            class:
                              props.row.maintenanceStatusCd === "MCSC000020"
                                ? "blinking"
                                : null,
                            attrs: {
                              outline: "",
                              square: "",
                              color: _vm.setTagColor(
                                props.row.maintenanceStatusCd
                              ),
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.btnClicked2(props.row)
                              },
                            },
                          },
                          [
                            props.row.maintenanceStatusCd === "MCSC000020"
                              ? _c("q-avatar", {
                                  attrs: {
                                    icon: "alarm",
                                    color: "red",
                                    "text-color": "white",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setTagName(props.row.maintenanceStatusCd)
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }